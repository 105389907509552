import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";

import { unwrapFirst } from "../util";
import {
  Layout,
  BannerHeading,
  Section,
  HorizontalContainer,
  FormattedRichText,
} from "../components/legacy";

type Props = {
  data: GatsbyTypes.TermsPageQuery;
};

export const query = graphql`
  query TermsPage {
    prismicTermsPage {
      data {
        page_heading
        content {
          raw
        }
      }
    }
  }
`;

export default function TermsPage({ data }: Props) {
  const page = data.prismicTermsPage?.data;
  if (!page) throw new Error("page missing");

  return (
    <Layout>
      <Helmet>
        <title>Terms &amp; Conditions | Shipamax</title>
      </Helmet>

      <BannerHeading title={page.page_heading || ""} />

      <Section>
        <HorizontalContainer>
          <FormattedRichText render={page.content?.raw} />
        </HorizontalContainer>
      </Section>
    </Layout>
  );
}
